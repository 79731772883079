// ./src/templates/blog-post.js
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"

import {
  localize,
  createMarkup,
  getAccordionFromMarkup,
  replaceListsFromEditorial,
  getImageUrl,
  stripHtml,
} from "../utils"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"
import ProductBox from "../components/product-box"
import Accordion from "../components/accordion"

const EditorialPage = ({
  pageContext,
  intl,
}) => {
  const editorialData = pageContext.editorial
  const editorial = localize(editorialData, intl.locale)
  const mainProduct = localize(editorialData.main_product, intl.locale)

  const [accordionData, setAccordionData] = useState([])
  const [textParts, setTextParts] = useState([])
  useEffect(() => {
    setAccordionData(getAccordionFromMarkup(editorial.body))
    const newBody = replaceListsFromEditorial(editorial.body)
    setTextParts(newBody.split("{{ACCORDION}}"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Layout name="p-editorial">
      <Helmet
        bodyAttributes={{
          class: 'body-for-sticky',
        }}
      />
      <Seo
        title={editorial.title}
        imageUrl={getImageUrl(editorial.image_cover)}
        description={stripHtml(editorial.newBody)}
      />
      <div className="p-editorial__header">
        <div className="a-container">
          <Image className="p-editorial__cover" type="fluid" image={editorial.image_cover} />
        </div>
      </div>

      <div className="a-container">
        <div className="p-editorial__content">
          <div className="p-editorial__main">
            <h1 class="a-section__title a-section__title--main a-section__title--decoration">
              {editorial.title}
            </h1>
            <div className="p-editorial__body">
              {textParts.map((text, index) => {
                return (
                  <>
                    <div
                      className="p-editorial__text"
                      dangerouslySetInnerHTML={createMarkup(text)}
                    />
                    {accordionData[index] && (
                      <Accordion items={accordionData[index]} />
                    )}
                  </>
                )
              })}
            </div>
          </div>
          <div className="p-editorial__sidebar">
            {Object.keys(mainProduct).length !== 0 && (
              <ProductBox
                product={mainProduct}
                isRelated={false}
                style={{ position: "sticky", top: "160px" }}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(EditorialPage)
