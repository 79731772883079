import React, { useState } from "react"
import { UilAngleDown } from "@iconscout/react-unicons"

import { stripHtml } from "../utils"

function AccordionSection(props) {
  const [active, setActive] = useState(props.active)

  function toggleAccordion() {
    setActive(!active)
  }

  return (
    <div
      className={`accordion__section ${active ? "accordion__section--active" : ""}`}
      onClick={!props.simple ? toggleAccordion : null}
      onKeyDown={null}
    >
      <div className={`accordion__title ${!props.simple ? 'accordion__title--clickable' : ''}`}>
        <span dangerouslySetInnerHTML={{ __html: stripHtml(props.title) }} />
        {!props.simple && (
          <div className="accordion__icon">
            <UilAngleDown size={30} />
          </div>
        )}
      </div>

      <div className="accordion__content">
        <p dangerouslySetInnerHTML={{ __html: props.content }} />
      </div>
    </div>
  )
}

function Accordion(props) {
  return (
    <div className="accordion">
      {props.items.map(item => (
        <AccordionSection
          title={item.title}
          simple={item.simple}
          content={item.content}
          active={item.active}
        />
      ))}
    </div>
  )
}

export default Accordion
